import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Page from './components/Page';
import Home from "./components/pages/Home";
import About from "./components/pages/About";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path='/contact' element={<Page><h1>Contact</h1></Page>} />
        </Routes>

      </Router>
    </div>
  );
}

export default App;
