import {NavLink, Link} from 'react-router-dom';


export default function Navbar() {
    return (
        <nav className="navbar">
            <Link to="/" className={"navbar-brand"}>#VANLIFE</Link>
            <ul className={"nav justify-content-end"}>
                <li className={'nav-item'}><NavLink className={'nav-link'} activeClassName={'active'}
                                                    to="/about">About</NavLink></li>
                <li className={'nav-item'}><NavLink className={'nav-link'} activeClassName={'active'}
                                                    to="/contact">Contact</NavLink></li>
            </ul>
        </nav>
    )
}